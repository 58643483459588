/* eslint-disable camelcase */
import React, { FC } from 'react';
import {
  PageLayout,
  PageHeading,
  ThumbnailsTitle,
  SuccessStories,
  BeforeAfterSlider,
  CharitySection,
  FAQ,
} from 'components';
import { graphql } from 'gatsby';
import { CommunityPageType } from 'types';

const Community: FC<CommunityPageType> = ({ data }: any) => {
  const {
    title,
    tagline,
    description,
    pageTheme,
    slug,
    eventType,
    seo,
    eventsList,
    successStoryHeadingAndTagline,
    success_stories,
    before_and_afters,
    successStoryBeforeAndAfterSliderTitle,
    successStoryResultSliderTitle,
    CharitieSection,
    showCharitySection,
    banner,
    faq: { title: faqTitle, tagline: faqTagline, faqs },
  } = data.CommunityPage;
  const isdevCompetition = data.site.siteMetadata.showCompetition === 'true';
  const mappedEventList = eventsList.map(
    (mappedEvent: any) => mappedEvent.event
  );
  return (
    <PageLayout
      title="Community"
      metaTitle={seo?.metaTitle ?? title}
      description={seo?.metaDescription}
      image={seo?.shareImage?.localFile?.publicURL}
      className={`${pageTheme}`}
      delaySec={0.5}
      bannerTitle={banner?.title}
      bannerTagline={banner?.tagline}
      bannerImage={banner?.image}
      bannerButton={banner?.ctaButton}
      bannerPlaceholder={banner?.postcodePlaceholder}
      faqs={faqs}
    >
      <div className="container">
        <PageHeading
          title={title}
          subtitle={tagline}
          description={description}
          level={1}
        />
        <ThumbnailsTitle
          eventType={eventType}
          events={mappedEventList}
          communityPageSlug={slug}
          showCompetition={isdevCompetition}
        />
      </div>
      <div className="container">
        {showCharitySection && (
          <CharitySection
            CharityInfo={CharitieSection}
            communityPageSlug={slug}
          />
        )}
        <SuccessStories
          title={successStoryHeadingAndTagline.heading}
          tagline={successStoryHeadingAndTagline.tagline}
          sucessStorySlides={success_stories}
          successStoryResultSliderTitle={successStoryResultSliderTitle}
        />
        <BeforeAfterSlider
          slides={before_and_afters}
          successStoryBeforeAndAfterSliderTitle={
            successStoryBeforeAndAfterSliderTitle
          }
        />
        <section className="home-page-faq-section">
          <PageHeading title={faqTitle} subtitle={faqTagline} />
          <FAQ Faqs={faqs} />
        </section>
      </div>
    </PageLayout>
  );
};

export default Community;

export const query = graphql`
  query CommunityPageQuery {
    CommunityPage: strapiCommunityPage {
      title
      tagline
      description: pageDescription
      pageTheme
      slug
      showCharitySection
      banner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      seo {
        metaDescription
        metaTitle
        shareImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 400, height: 400)
            }
          }
        }
      }
      successStoryBeforeAndAfterSliderTitle
      successStoryResultSliderTitle
      eventType {
        id
        title
        content
      }
      eventsList {
        event {
          id
          title
          summary
          slug
          FromTime
          ToTime
          showLiveButton
          ctaLabel
          ctaLink
          images {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 400, height: 400)
              }
            }
          }
        }
      }
      CharitieSection {
        title
        tagline
        description
        stats {
          title
          value
          id
        }
        events {
          id
          title
          description
          date
          ctaLink
          ctaLabel
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 452, height: 309)
              }
            }
          }
        }
      }
      successStoryHeadingAndTagline {
        heading
        tagline
      }
      faq {
        tagline
        title
        faqs {
          answer
          title
        }
      }
      success_stories {
        id
        name
        content
        highlight
        videoImage {
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              publicURL
            }
          }
          video {
            localFile {
              publicURL
            }
          }
        }
      }
      before_and_afters {
        id
        name
        highlight
        content
        videoImage {
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              publicURL
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        showCompetition
      }
    }
  }
`;
// TODO conditionally add before_and_afters
// video {
//   localFile {
//     url
//   }
// }
